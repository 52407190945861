const errores = [
  {
    Servicio: "Canal.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "Canal.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Canal. Identificador no recibido.",
  },
  {
    Servicio: "Canal.",
    ErrorCode: "not_found",
    CompleteErrorCode: "Canal.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Canal. Dato no encontrado. ",
  },
  {
    Servicio: "Canal.",
    ErrorCode: "not_valid",
    CompleteErrorCode: "Canal.not_valid",
    UncompleteMessage: "No válido.",
    CompleteMessage: "Canal. No válido.",
  },
  {
    Servicio: "BL.",
    ErrorCode: "found_record",
    CompleteErrorCode: "BL.found_record",
    UncompleteMessage:
      "No hemos podido identificarte, lamentablemente no tenemos un producto para vos.",
    CompleteMessage:
      "No hemos podido identificarte, lamentablemente no tenemos un producto para vos.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "update_ok",
    CompleteErrorCode: "Core.update_ok",
    UncompleteMessage: "Actualización exitosa.",
    CompleteMessage: "Actualización exitosa.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "delete_ok",
    CompleteErrorCode: "Core.delete_ok",
    UncompleteMessage: "Borrado exitoso.",
    CompleteMessage: "Borrado exitoso.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "activate_ok",
    CompleteErrorCode: "Core.activate_ok",
    UncompleteMessage: "Activación exitosa. ",
    CompleteMessage: "Activación exitosa. ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "update_order_ok",
    CompleteErrorCode: "Core.update_order_ok",
    UncompleteMessage: "Actualización de orden exitosa. ",
    CompleteMessage: "Actualización de orden exitosa. ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "invalid_token",
    CompleteErrorCode: "Core.invalid_token",
    UncompleteMessage: "Autorización no válida.",
    CompleteMessage: "Autorización no válida.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Critical.bad_input_received",
    CompleteErrorCode: "Solicitud.Critical.bad_input_received",
    UncompleteMessage: "Un agente se contactara con usted.",
    CompleteMessage: "Un agente se contactara con usted.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "Cuota.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "Cuota. Dato no ingresado",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "missing_nro_cuota",
    CompleteErrorCode: "Cuota.missing_nro_cuota",
    UncompleteMessage: "Dato no ingresado (número de cuota)",
    CompleteMessage: "Cuota. Dato no ingresado (número de cuota)",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "Cuota.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Cuota. Identificador no recibido.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "not_found",
    CompleteErrorCode: "Cuota.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Cuota. Dato no encontrado. ",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "out_of_range",
    CompleteErrorCode: "Cuota.out_of_range",
    UncompleteMessage: "Número de cuotas fuera de rango. ",
    CompleteMessage: "Cuota. Número de cuotas fuera de rango. ",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "Vencimiento.missing",
    CompleteErrorCode: "Cuota.Vencimiento.missing",
    UncompleteMessage: "Dato no ingresado (Vencimiento)",
    CompleteMessage: "Cuota. Dato no ingresado (Vencimiento)",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "Vencimiento.not_valid",
    CompleteErrorCode: "Cuota.Vencimiento.not_valid",
    UncompleteMessage: "Fecha de Vencimiento no válida.",
    CompleteMessage: "Cuota. Fecha de Vencimiento no válida.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "Monto.missing",
    CompleteErrorCode: "Cuota.Monto.missing",
    UncompleteMessage: "Dato no ingresado (Monto)",
    CompleteMessage: "Cuota. Dato no ingresado (Monto)",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "Monto.not_valid",
    CompleteErrorCode: "Cuota.Monto.not_valid",
    UncompleteMessage: "Monto no válido.",
    CompleteMessage: "Cuota. Monto no válido.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "Saldo.missing",
    CompleteErrorCode: "Cuota.Saldo.missing",
    UncompleteMessage: "Dato no ingresado (Saldo)",
    CompleteMessage: "Cuota. Dato no ingresado (Saldo)",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "Saldo.not_valid",
    CompleteErrorCode: "Cuota.Saldo.not_valid",
    UncompleteMessage: "Saldo no válido.",
    CompleteMessage: "Cuota. Saldo no válido.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "FechaCuota.not_valid",
    CompleteErrorCode: "Cuota.FechaCuota.not_valid",
    UncompleteMessage: "Fecha Saldo de cuota no válida.",
    CompleteMessage: "Cuota. Fecha Saldo de cuota no válida.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "monto_mora_not_valid",
    CompleteErrorCode: "Cuota.monto_mora_not_valid",
    UncompleteMessage: "Monto de mora no válido.",
    CompleteMessage: "Cuota. Monto de mora no válido.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "saldo_mora_not_valid",
    CompleteErrorCode: "Cuota.saldo_mora_not_valid",
    UncompleteMessage: "Saldo no válido.",
    CompleteMessage: "Cuota. Saldo no válido.",
  },
  {
    Servicio: "Cuota.",
    ErrorCode: "cannot_upd_or_delete",
    CompleteErrorCode: "Cuota.cannot_upd_or_delete",
    UncompleteMessage:
      "Cuota ya facturada o con saldo, no puede actualizar o borrar este registro. ",
    CompleteMessage:
      "Cuota. Cuota ya facturada o con saldo, no puede actualizar o borrar este registro. ",
  },
  {
    Servicio: "Escenario.",
    ErrorCode: "no_prod_flia_founded",
    CompleteErrorCode: "Escenario.no_prod_flia_founded",
    UncompleteMessage: "No hay ningun escenario disponible en este momento. ",
    CompleteMessage:
      "Escenario. No hay ningun escenario disponible en este momento. ",
  },
  {
    Servicio: "ProductoFinancieroFamilia.",
    ErrorCode: "no_data",
    CompleteErrorCode: "ProductoFinancieroFamilia.no_data",
    UncompleteMessage: "No hay ningun escenario disponible en este momento. ",
    CompleteMessage:
      "Escenario. No hay ningun escenario disponible en este momento. ",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "MatrizOferta.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "MatrizOferta. Dato no ingresado",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "missing_name",
    CompleteErrorCode: "MatrizOferta.missing_name",
    UncompleteMessage: "Nombre no ingresado.",
    CompleteMessage: "MatrizOferta. Nombre no ingresado.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "MatrizOferta.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "MatrizOferta. Identificador no recibido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "not_found",
    CompleteErrorCode: "MatrizOferta.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "MatrizOferta. Dato no encontrado. ",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "EstadoPA.Not_found",
    CompleteErrorCode: "MatrizOferta.EstadoPA.Not_found",
    UncompleteMessage:
      "Dato no encontrado para estado de Préstamo Amortizable. ",
    CompleteMessage:
      "MatrizOferta. Dato no encontrado para estado de Préstamo Amortizable. ",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "EstadoPA.not_valid",
    CompleteErrorCode: "MatrizOferta.EstadoPA.not_valid",
    UncompleteMessage: "Estado de Préstamo Amortizable no válido.",
    CompleteMessage: "MatrizOferta. Estado de Préstamo Amortizable no válido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "EstadoLC.Not_found",
    CompleteErrorCode: "MatrizOferta.EstadoLC.Not_found",
    UncompleteMessage: "Dato no encontrado para estado de Lïnea de Crédito. ",
    CompleteMessage:
      "MatrizOferta. Dato no encontrado para estado de Lïnea de Crédito. ",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "EstadoLC.not_valid",
    CompleteErrorCode: "MatrizOferta.EstadoLC.not_valid",
    UncompleteMessage: "Estado de Línea de Crédito no válido.",
    CompleteMessage: "MatrizOferta. Estado de Línea de Crédito no válido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Utilizacion.not_valid",
    CompleteErrorCode: "MatrizOferta.Utilizacion.not_valid",
    UncompleteMessage: "Utilización no válida.",
    CompleteMessage: "MatrizOferta. Utilización no válida.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Vinculo.not_valid",
    CompleteErrorCode: "MatrizOferta.Vinculo.not_valid",
    UncompleteMessage: "Vínculo no válido.",
    CompleteMessage: "MatrizOferta. Vínculo no válido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "NivelIngreso.missing_id",
    CompleteErrorCode: "MatrizOferta.NivelIngreso.missing_id",
    UncompleteMessage: "Identificador no recibido para Nivel de Ingreso.",
    CompleteMessage:
      "MatrizOferta. Identificador no recibido para Nivel de Ingreso.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "NivelIngreso.not_found",
    CompleteErrorCode: "MatrizOferta.NivelIngreso.not_found",
    UncompleteMessage: "Dato no encontrado para Nivel de Ingreso. ",
    CompleteMessage: "MatrizOferta. Dato no encontrado para Nivel de Ingreso. ",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Actualidad.not_valid",
    CompleteErrorCode: "MatrizOferta.Actualidad.not_valid",
    UncompleteMessage: "Actualidad no válida.",
    CompleteMessage: "MatrizOferta. Actualidad no válida.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Comportamiento.Not_found",
    CompleteErrorCode: "MatrizOferta.Comportamiento.Not_found",
    UncompleteMessage: "Dato no encontrado para Comportamiento.",
    CompleteMessage: "MatrizOferta. Dato no encontrado para Comportamiento.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Comportamiento.not_valid",
    CompleteErrorCode: "MatrizOferta.Comportamiento.not_valid",
    UncompleteMessage: "Comportamiento no válido.",
    CompleteMessage: "MatrizOferta. Comportamiento no válido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Segmento.not_valid",
    CompleteErrorCode: "MatrizOferta.Segmento.not_valid",
    UncompleteMessage: "Segmento no válido.",
    CompleteMessage: "MatrizOferta. Segmento no válido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Contactabilidad.not_valid",
    CompleteErrorCode: "MatrizOferta.Contactabilidad.not_valid",
    UncompleteMessage: "Contactabilidad no válida.",
    CompleteMessage: "MatrizOferta. Contactabilidad no válida.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Elegibilidad.not_valid",
    CompleteErrorCode: "MatrizOferta.Elegibilidad.not_valid",
    UncompleteMessage: "Elegibilidad no válida.",
    CompleteMessage: "MatrizOferta. Elegibilidad no válida.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Scoring.not_valid",
    CompleteErrorCode: "MatrizOferta.Scoring.not_valid",
    UncompleteMessage: "Scoring no válido.",
    CompleteMessage: "MatrizOferta. Scoring no válido.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "ValidoDesde.not_found",
    CompleteErrorCode: "MatrizOferta.ValidoDesde.not_found",
    UncompleteMessage: "Dato no encontrado para Fecha Desde de Validez",
    CompleteMessage:
      "MatrizOferta. Dato no encontrado para Fecha Desde de Validez",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "ValidoHasta.not_valid",
    CompleteErrorCode: "MatrizOferta.ValidoHasta.not_valid",
    UncompleteMessage: "Fecha Hasta no válida.",
    CompleteMessage: "MatrizOferta. Fecha Hasta no válida.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "ValidoHasta.not_found",
    CompleteErrorCode: "MatrizOferta.ValidoHasta.not_found",
    UncompleteMessage: "Dato no encontrado para Fecha Hasta de Validez",
    CompleteMessage:
      "MatrizOferta. Dato no encontrado para Fecha Hasta de Validez",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "ValidoHasta.out_of_range",
    CompleteErrorCode: "MatrizOferta.ValidoHasta.out_of_range",
    UncompleteMessage: "Fecha Hasta fuera de rango. ",
    CompleteMessage: "MatrizOferta. Fecha Hasta fuera de rango. ",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Row_conflict",
    CompleteErrorCode: "MatrizOferta.Row_conflict",
    UncompleteMessage: "Fila en conflicto con fila ya existente.",
    CompleteMessage: "MatrizOferta. Fila en conflicto con fila ya existente.",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "Document.missing_data",
    CompleteErrorCode: "MatrizOferta.Document.missing_data",
    UncompleteMessage: "Dato no ingresado (Documento)",
    CompleteMessage: "MatrizOferta. Dato no ingresado (Documento)",
  },
  {
    Servicio: "MatrizOferta.",
    ErrorCode: "already_activated",
    CompleteErrorCode: "MatrizOferta.already_activated",
    UncompleteMessage: "Fila ya activada. ",
    CompleteMessage: "MatrizOferta. Fila ya activada. ",
  },
  {
    Servicio: "PreAsignado.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "PreAsignado.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "PreAsignado. Dato no ingresado",
  },
  {
    Servicio: "PreAsignado.",
    ErrorCode: "missing_name",
    CompleteErrorCode: "PreAsignado.missing_name",
    UncompleteMessage: "Nombre no ingresado.",
    CompleteMessage: "PreAsignado. Nombre no ingresado.",
  },
  {
    Servicio: "PreAsignado.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "PreAsignado.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "PreAsignado. Identificador no recibido.",
  },
  {
    Servicio: "PreAsignado.",
    ErrorCode: "not_found",
    CompleteErrorCode: "PreAsignado.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "PreAsignado. Dato no encontrado. ",
  },
  {
    Servicio: "PreAsignado.",
    ErrorCode: "existing_voucher",
    CompleteErrorCode: "PreAsignado.existing_voucher",
    UncompleteMessage:
      "Vale ya generado, no puede actualizar o borrar este registro. ",
    CompleteMessage:
      "PreAsignado. Vale ya generado, no puede actualizar o borrar este registro. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "Pricing.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "Pricing. Dato no ingresado",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "missing_name",
    CompleteErrorCode: "Pricing.missing_name",
    UncompleteMessage: "Nombre no ingresado.",
    CompleteMessage: "Pricing. Nombre no ingresado.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "Pricing.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Pricing. Identificador no recibido.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "not_found",
    CompleteErrorCode: "Pricing.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Pricing. Dato no encontrado. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "MontoMinimo.not_found",
    CompleteErrorCode: "Pricing.MontoMinimo.not_found",
    UncompleteMessage: "Dato no encontrado para Monto mínimo.",
    CompleteMessage: "Pricing. Dato no encontrado para Monto mínimo.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "MontoMinimo.less_than_zero",
    CompleteErrorCode: "Pricing.MontoMinimo.less_than_zero",
    UncompleteMessage: "Monto Mínimo es menor a cero. ",
    CompleteMessage: "Pricing. Monto Mínimo es menor a cero. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "MontoMaximo.Not_found",
    CompleteErrorCode: "Pricing.MontoMaximo.Not_found",
    UncompleteMessage: "Dato no encontrado para Monto máximo.",
    CompleteMessage: "Pricing. Dato no encontrado para Monto máximo.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "MontoMaximo.less_than_minimun",
    CompleteErrorCode: "Pricing.MontoMaximo.less_than_minimun",
    UncompleteMessage: "Monto Máximo no puede ser menor al Mínimo. ",
    CompleteMessage: "Pricing. Monto Máximo no puede ser menor al Mínimo. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Tasa.not_found",
    CompleteErrorCode: "Pricing.Tasa.not_found",
    UncompleteMessage: "Dato no encontrado para Tasa",
    CompleteMessage: "Pricing. Dato no encontrado para Tasa",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Tasa.out_of_range",
    CompleteErrorCode: "Pricing.Tasa.out_of_range",
    UncompleteMessage: "Tasa fuera de rango. ",
    CompleteMessage: "Pricing. Tasa fuera de rango. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "TasaMora.not_found",
    CompleteErrorCode: "Pricing.TasaMora.not_found",
    UncompleteMessage: "Dato no encontrado para Tasa de Mora",
    CompleteMessage: "Pricing. Dato no encontrado para Tasa de Mora",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "TasaMora.out_of_range",
    CompleteErrorCode: "Pricing.TasaMora.out_of_range",
    UncompleteMessage: "Tasa de Mora fuera de rango. ",
    CompleteMessage: "Pricing. Tasa de Mora fuera de rango. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "PlazoCuotaMin.not_found",
    CompleteErrorCode: "Pricing.PlazoCuotaMin.not_found",
    UncompleteMessage: "Dato no encontrado para Plazo mínimo.",
    CompleteMessage: "Pricing. Dato no encontrado para Plazo mínimo.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "PlazoCuotaMin.less_than_minimun",
    CompleteErrorCode: "Pricing.PlazoCuotaMin.less_than_minimun",
    UncompleteMessage: "Plazo Mínimo menor a mínimo permitido.",
    CompleteMessage: "Pricing. Plazo Mínimo menor a mínimo permitido.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "PlazoCuotaMax.not_found",
    CompleteErrorCode: "Pricing.PlazoCuotaMax.not_found",
    UncompleteMessage: "Dato no encontrado para Plazo máximo.",
    CompleteMessage: "Pricing. Dato no encontrado para Plazo máximo.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "PlazoCuotaMax.less_than_minimun",
    CompleteErrorCode: "Pricing.PlazoCuotaMax.less_than_minimun",
    UncompleteMessage: "Plazo Máximo menor al Plazo Mínimo.",
    CompleteMessage: "Pricing. Plazo Máximo menor al Plazo Mínimo.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "PlazoCuotaMax.not_valid",
    CompleteErrorCode: "Pricing.PlazoCuotaMax.not_valid",
    UncompleteMessage: "Plazo máximo de Cuota no válido.",
    CompleteMessage: "Pricing. Plazo máximo de Cuota no válido.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Originacion.missing_data",
    CompleteErrorCode: "Pricing.Originacion.missing_data",
    UncompleteMessage: "Dato no ingresado (Originación)",
    CompleteMessage: "Pricing. Dato no ingresado (Originación)",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Originacion.out_of_range",
    CompleteErrorCode: "Pricing.Originacion.out_of_range",
    UncompleteMessage: "Monto de Originación fuera de rango. ",
    CompleteMessage: "Pricing. Monto de Originación fuera de rango. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Administracion.missing_data",
    CompleteErrorCode: "Pricing.Administracion.missing_data",
    UncompleteMessage: "Dato no ingresado (Administración)",
    CompleteMessage: "Pricing. Dato no ingresado (Administración)",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Administracion.out_of_range",
    CompleteErrorCode: "Pricing.Administracion.out_of_range",
    UncompleteMessage: "Costo de Administración fuera de rango. ",
    CompleteMessage: "Pricing. Costo de Administración fuera de rango. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Seguro.missing_data",
    CompleteErrorCode: "Pricing.Seguro.missing_data",
    UncompleteMessage: "Seguro. Dato no ingresado",
    CompleteMessage: "Pricing. Seguro. Dato no ingresado",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Seguro.out_of_range",
    CompleteErrorCode: "Pricing.Seguro.out_of_range",
    UncompleteMessage: "Seguro fuera de rango. ",
    CompleteMessage: "Pricing. Seguro fuera de rango. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "IVA.missing_data",
    CompleteErrorCode: "Pricing.IVA.missing_data",
    UncompleteMessage: "IVA. Dato no ingresado",
    CompleteMessage: "Pricing. IVA. Dato no ingresado",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "IVA.out_of_range",
    CompleteErrorCode: "Pricing.IVA.out_of_range",
    UncompleteMessage: "IVA fuera de rango. ",
    CompleteMessage: "Pricing. IVA fuera de rango. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "missing_since_date",
    CompleteErrorCode: "Pricing.missing_since_date",
    UncompleteMessage: "Fecha Desde no ingresada. ",
    CompleteMessage: "Pricing. Fecha Desde no ingresada. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "missing_due_date",
    CompleteErrorCode: "Pricing.missing_due_date",
    UncompleteMessage: "Dato no ingresado (Fecha hasta)",
    CompleteMessage: "Pricing. Dato no ingresado (Fecha hasta)",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "invalid_due_date",
    CompleteErrorCode: "Pricing.invalid_due_date",
    UncompleteMessage: "Fecha de Expiración no válida.",
    CompleteMessage: "Pricing. Fecha de Expiración no válida.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "invalid_date_range",
    CompleteErrorCode: "Pricing.invalid_date_range",
    UncompleteMessage: "Rango de Fechas no válido.",
    CompleteMessage: "Pricing. Rango de Fechas no válido.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "no_default_pricing",
    CompleteErrorCode: "Pricing.no_default_pricing",
    UncompleteMessage: "No se determinó Pricing por defecto. ",
    CompleteMessage: "Pricing. No se determinó Pricing por defecto. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "cannot_upd_or_delete",
    CompleteErrorCode: "Pricing.cannot_upd_or_delete",
    UncompleteMessage:
      "Registro ya activado, no puede actualizar o borrar este registro. ",
    CompleteMessage:
      "Pricing. Registro ya activado, no puede actualizar o borrar este registro. ",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Fondeo.missing_data",
    CompleteErrorCode: "Pricing.Fondeo.missing_data",
    UncompleteMessage: "Dato no ingresado (Fondeo)",
    CompleteMessage: "Pricing. Dato no ingresado (Fondeo)",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Fondeo.not_valid",
    CompleteErrorCode: "Pricing.Fondeo.not_valid",
    UncompleteMessage: "Fondeo no válido.",
    CompleteMessage: "Pricing. Fondeo no válido.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Squema.id_missing",
    CompleteErrorCode: "Pricing.Squema.id_missing",
    UncompleteMessage: "Esquema de Pricing no ingresado.",
    CompleteMessage: "Pricing. Esquema de Pricing no ingresado.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "Squema.type_not_valid",
    CompleteErrorCode: "Pricing.Squema.type_not_valid",
    UncompleteMessage: "Tipo de Esquema no es válido.",
    CompleteMessage: "Pricing. Tipo de Esquema no es válido.",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "TopeInteres.missing_data",
    CompleteErrorCode: "Pricing.TopeInteres.missing_data",
    UncompleteMessage: "Tope de Interés. Dato no ingresado",
    CompleteMessage: "Pricing. Tope de Interés. Dato no ingresado",
  },
  {
    Servicio: "Pricing.",
    ErrorCode: "TopeInteres.out_of_range",
    CompleteErrorCode: "Pricing.TopeInteres.out_of_range",
    UncompleteMessage: "Tope de Interés fuera de rango. ",
    CompleteMessage: "Pricing. Tope de Interés fuera de rango. ",
  },
  {
    Servicio: "ProductoFinanciero.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "ProductoFinanciero.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "ProductoFinanciero. Dato no ingresado",
  },
  {
    Servicio: "ProductoFinanciero.",
    ErrorCode: "not_found",
    CompleteErrorCode: "ProductoFinancieroRango.not_found",
    UncompleteMessage: "Rango no encontrado",
    CompleteMessage: "ProductoFinanciero. Rango no encontrado",
  },
  {
    Servicio: "ProductoFinanciero.",
    ErrorCode: "missing_name",
    CompleteErrorCode: "ProductoFinanciero.missing_name",
    UncompleteMessage: "Nombre no ingresado.",
    CompleteMessage: "ProductoFinanciero. Nombre no ingresado.",
  },
  {
    Servicio: "ProductoFinanciero.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "ProductoFinanciero.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "ProductoFinanciero. Identificador no recibido.",
  },
  {
    Servicio: "ProductoFinanciero.",
    ErrorCode: "not_found",
    CompleteErrorCode: "ProductoFinanciero.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "ProductoFinanciero. Dato no encontrado. ",
  },
  {
    Servicio: "ProductoFinanciero.",
    ErrorCode: "default_not_found",
    CompleteErrorCode: "ProductoFinanciero.default_not_found",
    UncompleteMessage: "Producto Financiero por defecto, no definido",
    CompleteMessage:
      "ProductoFinanciero. Producto Financiero por defecto, no definido",
  },
  {
    CompleteErrorCode: "",
    CompleteMessage: " ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "Solicitud.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "Solicitud. Dato no ingresado",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "ValesVigentes.user_has_another_vale",
    CompleteErrorCode: "Solicitud.ValesVigentes.user_has_another_vale",
    UncompleteMessage: "Ya hay otro vale con este documento",
    CompleteMessage: "Solicitud. Ya hay otro vale con este documento",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "ValesVigentes.user_has_another_vale",
    CompleteErrorCode: "Solicitud.ValesVigentes.user_has_another_vale",
    UncompleteMessage: "Existe otro vale con este documento",
    CompleteMessage:
      "Solicitud. Vales Vigentes. Existe otro vale con este documento",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "concurrent_issue",
    CompleteErrorCode: "Solicitud.concurrent_issue",
    UncompleteMessage: "Multiples solicitudes simultaneas detectadas",
    CompleteMessage: "Solicitud. Multiples solicitudes simultaneas detectadas",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "Solicitud.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Solicitud. Identificador no recibido.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "not_found",
    CompleteErrorCode: "Solicitud.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Solicitud. Dato no encontrado. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Mobile.missing",
    CompleteErrorCode: "Solicitud.Mobile.missing",
    UncompleteMessage: "Número de Celular no recibido.",
    CompleteMessage: "Solicitud. Número de Celular no recibido.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.get_exception",
    CompleteErrorCode: "Solicitud.Person.get_exception",
    UncompleteMessage: "Error al buscar Solicitante. ",
    CompleteMessage: "Solicitud. Error al buscar Solicitante. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.already_exists",
    CompleteErrorCode: "Solicitud.Person.already_exists",
    UncompleteMessage: "Solicitante ya existe. ",
    CompleteMessage: "Solicitud. Solicitante ya existe. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.create_error",
    CompleteErrorCode: "Solicitud.Person.create_error",
    UncompleteMessage: "Error al crear Solicitante. ",
    CompleteMessage: "Solicitud. Error al crear Solicitante. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.modify_error",
    CompleteErrorCode: "Solicitud.Person.modify_error",
    UncompleteMessage: "Error al modificar Solicitante. ",
    CompleteMessage: "Solicitud. Error al modificar Solicitante. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.name_missing",
    CompleteErrorCode: "Solicitud.Person.name_missing",
    UncompleteMessage: "Nombre no ingresado.",
    CompleteMessage: "Solicitud. Nombre no ingresado.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.surname_missing",
    CompleteErrorCode: "Solicitud.Person.surname_missing",
    UncompleteMessage: "Apellido no ingresado.",
    CompleteMessage: "Solicitud. Apellido no ingresado.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.birthdate_missing",
    CompleteErrorCode: "Solicitud.Person.birthdate_missing",
    UncompleteMessage: "Fecha de Nacimiento no recibida.",
    CompleteMessage: "Solicitud. Fecha de Nacimiento no recibida.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Person.wrong_age",
    CompleteErrorCode: "Solicitud.Person.wrong_age",
    UncompleteMessage: "Edad fuera de rango.",
    CompleteMessage: "Solicitud. Edad fuera de rango.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "expired",
    CompleteErrorCode: "Solicitud.expired",
    UncompleteMessage:
      "Esta solicitud no puede ser continuada, debe iniciar una nueva.",
    CompleteMessage:
      "Esta solicitud no puede ser continuada, debe iniciar una nueva.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.to_user_error",
    CompleteErrorCode: "Person.Person.to_user_error",
    UncompleteMessage: "Error en el usuario.",
    CompleteMessage: "Person. Error en el usuario.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.find_error",
    CompleteErrorCode: "Person.Person.find_error",
    UncompleteMessage: "Se encontro un error.",
    CompleteMessage: "Person. Se encontro un error.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.mail_used_other_user",
    CompleteErrorCode: "Person.mail_used_other_user",
    UncompleteMessage: "El mail es usado por otra persona.",
    CompleteMessage: "Person.El mail es usado por otra persona.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.DocFrontal.find_error",
    CompleteErrorCode: "Person.DocFrontal.find_error",
    UncompleteMessage: "Se encontro un error en el documento frontal.",
    CompleteMessage: "Person.Se encontro un error en el documento frontal.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.DocFrontal.missing",
    CompleteErrorCode: "Person.DocFrontal.missing",
    UncompleteMessage: "Falta el documento frontal.",
    CompleteMessage: "Person.Falta el documento frontal.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.DocFrontal.expired",
    CompleteErrorCode: "Person.DocFrontal.expired",
    UncompleteMessage: "Expiro el documento frontal.",
    CompleteMessage: "Person.Expiro el documento frontal.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.DocFrontal.no_validated",
    CompleteErrorCode: "Person.DocFrontal.no_validated",
    UncompleteMessage: "Documento frontal no validado.",
    CompleteMessage: "Person.Documento frontal no validado.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.missing",
    CompleteErrorCode: "Person.missing",
    UncompleteMessage: "No se encontro a la persona.",
    CompleteMessage: "Person.No se encontro a la persona.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.Gender.missing_data",
    CompleteErrorCode: "Person.Gender.missing_data",
    UncompleteMessage: "Falta informacion del genero.",
    CompleteMessage: "Person.Gender.Falta informacion del genero.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.name_missing",
    CompleteErrorCode: "Person.name_missing",
    UncompleteMessage: "Falta el nombre.",
    CompleteMessage: "Person.Falta el nombre.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.surname_missing",
    CompleteErrorCode: "Person.surname_missing",
    UncompleteMessage: "Falta el apellido.",
    CompleteMessage: "Person.Falta el apellido.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.birthdate_missing",
    CompleteErrorCode: "Person.birthdate_missing",
    UncompleteMessage: "Falta fecha de nacimiento.",
    CompleteMessage: "Person.Falta fecha de nacimiento.",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.wrong_age",
    CompleteErrorCode: "Person.wrong_age",
    UncompleteMessage: "Fecha de nacimiento no valida",
    CompleteMessage: "Person.Fecha de nacimiento no valida",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.Direccion.missing_data",
    CompleteErrorCode: "Person.Direccion.missing_data",
    UncompleteMessage: "Falta información de la dirección",
    CompleteMessage: "Person.Falta información de la dirección",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.Direccion.too_short",
    CompleteErrorCode: "Person.Direccion.too_short",
    UncompleteMessage: "Dirección muy corta",
    CompleteMessage: "Person.Dirección muy corta",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.Email.missing",
    CompleteErrorCode: "Person.Email.missing",
    UncompleteMessage: "No se encuentra el mail",
    CompleteMessage: "Person.No se encuentra el mail",
  },
  {
    Servicio: "Person.",
    ErrorCode: "Person.Email.wrong_format",
    CompleteErrorCode: "Person.Email.wrong_format",
    UncompleteMessage: "Formato de mail incorrecto",
    CompleteMessage: "Person.Formato de mail incorrecto",
  },
  {
    Servicio: "UsuarioBean.",
    ErrorCode: "UsuarioBean.missing_password",
    CompleteErrorCode: "UsuarioBean.missing_password",
    UncompleteMessage: "No se encuentra el password",
    CompleteMessage: "UsuarioBean.No se encuentra el password",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Email.missing",
    CompleteErrorCode: "Solicitud.Email.missing",
    UncompleteMessage: "Email no recibido. ",
    CompleteMessage: "Solicitud. Email no recibido. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Email.wrong_format",
    CompleteErrorCode: "Solicitud.Email.wrong_format",
    UncompleteMessage: "Email con formato incorrecto. ",
    CompleteMessage: "Solicitud. Email con formato incorrecto. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "State.missing",
    CompleteErrorCode: "Solicitud.State.missing",
    UncompleteMessage: "Departamento o Estado no ingresado. ",
    CompleteMessage: "Solicitud. Departamento o Estado no ingresado. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "State.not_found",
    CompleteErrorCode: "Solicitud.State.not_found",
    UncompleteMessage: "Dato no encontrado para Departamento o Estado. ",
    CompleteMessage:
      "Solicitud. Dato no encontrado para Departamento o Estado. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Province.missing",
    CompleteErrorCode: "Solicitud.Province.missing",
    UncompleteMessage: "Localidad o Provincia no ingresada. ",
    CompleteMessage: "Solicitud. Localidad o Provincia no ingresada. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Province.not_found",
    CompleteErrorCode: "Solicitud.Province.not_found",
    UncompleteMessage: "Dato no encontrado para Localidad o Provincia.",
    CompleteMessage:
      "Solicitud. Dato no encontrado para Localidad o Provincia.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "State.province_not_related",
    CompleteErrorCode: "Solicitud.State.province_not_related",
    UncompleteMessage:
      "Localidad/Provincia no relacionada a Departamento/Estado. ",
    CompleteMessage:
      "Solicitud. Localidad/Provincia no relacionada a Departamento/Estado. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "IngDeclarado.missing_data",
    CompleteErrorCode: "Solicitud.IngDeclarado.missing_data",
    UncompleteMessage: "Dato no ingresado (Ingreso declarado)\\",
    CompleteMessage: "Solicitud. Dato no ingresado (Ingreso declarado)\\",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "IngDeclarado.out_of_range",
    CompleteErrorCode: "Solicitud.IngDeclarado.out_of_range",
    UncompleteMessage: "Ingreso declarado fuera de rango. ",
    CompleteMessage: "Solicitud. Ingreso declarado fuera de rango. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Gender.missing_data",
    CompleteErrorCode: "Solicitud.Gender.missing_data",
    UncompleteMessage: "Dato no ingresado (Género)",
    CompleteMessage: "Solicitud. Dato no ingresado (Género)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Gender.not_found",
    CompleteErrorCode: "Solicitud.Gender.not_found",
    UncompleteMessage: "Dato no encontrado para Género. ",
    CompleteMessage: "Solicitud. Dato no encontrado para Género. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "RelLaboral.missing_data",
    CompleteErrorCode: "Solicitud.RelLaboral.missing_data",
    UncompleteMessage: "Dato no ingresado (Relación laboral)",
    CompleteMessage: "Solicitud. Dato no ingresado (Relación laboral)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "RelLaboral.not_valid",
    CompleteErrorCode: "Solicitud.RelLaboral.not_valid",
    UncompleteMessage: "Relación laboral no válida.",
    CompleteMessage: "Solicitud. Relación laboral no válida.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Monto.missing_data",
    CompleteErrorCode: "Solicitud.Monto.missing_data",
    UncompleteMessage: "Dato no ingresado (Monto)",
    CompleteMessage: "Solicitud. Dato no ingresado (Monto)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Receipt.missing_data",
    CompleteErrorCode: "Solicitud.Receipt.missing_data",
    UncompleteMessage: "Dato no ingresado (Comprobante)",
    CompleteMessage: "Solicitud. Dato no ingresado (Comprobante)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Validation.mobile_not_validated",
    CompleteErrorCode: "Solicitud.Validation.mobile_not_validated",
    UncompleteMessage: "Celular no validado.",
    CompleteMessage: "Solicitud. Celular no validado.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Validation.email_not_validated",
    CompleteErrorCode: "Solicitud.Validation.email_not_validated",
    UncompleteMessage: "Email no validado",
    CompleteMessage: "Solicitud. Email no validado",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Monto.out_of_range",
    CompleteErrorCode: "Solicitud.Monto.out_of_range",
    UncompleteMessage: "Monto solicitado fuera de rango. ",
    CompleteMessage: "Solicitud. Monto solicitado fuera de rango. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Monto.not_valid",
    CompleteErrorCode: "Solicitud.Monto.not_valid",
    UncompleteMessage: "Monto no válido.",
    CompleteMessage: "Solicitud. Monto no válido.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Monto.no_offer",
    CompleteErrorCode: "Solicitud.Monto.no_offer",
    UncompleteMessage: "Sin ofertas para el monto solicitado.",
    CompleteMessage: "Solicitud. Sin ofertas para el monto solicitado.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "FechaCuota1.missing_data",
    CompleteErrorCode: "Solicitud.FechaCuota1.missing_data",
    UncompleteMessage: "Dato no ingresado (Fecha primer cuota)",
    CompleteMessage: "Solicitud. Dato no ingresado (Fecha primer cuota)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "FechaCuota1.not_valid",
    CompleteErrorCode: "Solicitud.FechaCuota1.not_valid",
    UncompleteMessage: "Fecha de primer cuota no válida.",
    CompleteMessage: "Solicitud. Fecha de primer cuota no válida.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "FechaCuota1.out_of_range",
    CompleteErrorCode: "Solicitud.FechaCuota1.out_of_range",
    UncompleteMessage: "Fecha de primer cuota fuera de rango. ",
    CompleteMessage: "Solicitud. Fecha de primer cuota fuera de rango. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Plazo.missing_data",
    CompleteErrorCode: "Solicitud.Plazo.missing_data",
    UncompleteMessage: "Dato no ingresado (Plazo)",
    CompleteMessage: "Solicitud. Dato no ingresado (Plazo)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Plazo.out_of_range",
    CompleteErrorCode: "Solicitud.Plazo.out_of_range",
    UncompleteMessage: "Plazo solicitado fuera de rango. ",
    CompleteMessage: "Solicitud. Plazo solicitado fuera de rango. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Destino.missing_data",
    CompleteErrorCode: "Solicitud.Destino.missing_data",
    UncompleteMessage: "Dato no ingresado (Destino)",
    CompleteMessage: "Solicitud. Dato no ingresado (Destino)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Destino.wrong_destination",
    CompleteErrorCode: "Solicitud.Destino.wrong_destination",
    UncompleteMessage: "Destino del Préstamo no es válido. ",
    CompleteMessage: "Solicitud. Destino del Préstamo no es válido. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Amount.amount_exceeded",
    CompleteErrorCode: "Solicitud.Amount.amount_exceeded",
    UncompleteMessage: "Monto excede el permitido para red de cobranzas. ",
    CompleteMessage:
      "Solicitud. Monto excede el permitido para red de cobranzas. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Direccion.missing_data",
    CompleteErrorCode: "Solicitud.Direccion.missing_data",
    UncompleteMessage: "Dato no ingresado (Dirección)",
    CompleteMessage: "Solicitud. Dato no ingresado (Dirección)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "Direccion.too_short",
    CompleteErrorCode: "Solicitud.Direccion.too_short",
    UncompleteMessage: "Dirección ingresado muy corta. ",
    CompleteMessage: "Solicitud. Dirección ingresado muy corta. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "TYC.missing_data",
    CompleteErrorCode: "Solicitud.TYC.missing_data",
    UncompleteMessage: "Dato no ingresado (Términos y condiciones)",
    CompleteMessage: "Solicitud. Dato no ingresado (Términos y condiciones)",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "TYC.cannot_continue",
    CompleteErrorCode: "Solicitud.TYC.cannot_continue",
    UncompleteMessage:
      "Debe aceptar los Términos y Condiciones para continuar. ",
    CompleteMessage:
      "Solicitud. Debe aceptar los Términos y Condiciones para continuar. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "cannot_upd_or_delete",
    CompleteErrorCode: "Solicitud.cannot_upd_or_delete",
    UncompleteMessage:
      "Vale ya generado, no puede actualizar o borrar este registro. ",
    CompleteMessage:
      "Solicitud. Vale ya generado, no puede actualizar o borrar este registro. ",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "missing_password",
    CompleteErrorCode: "Solicitud.missing_password",
    UncompleteMessage: "Contraseña incorrecta.",
    CompleteMessage: "Solicitud. Contraseña incorrecta.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "missing_continue_or_contact_center",
    CompleteErrorCode: "Solicitud.missing_continue_or_contact_center",
    UncompleteMessage:
      "Debe indicar si continúa o prefiere ser contactado por Contact Center",
    CompleteMessage:
      "Solicitud. Debe indicar si continúa o prefiere ser contactado por Contact Center",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "continue_contact_center_forbidden",
    CompleteErrorCode: "Solicitud.continue_contact_center_forbidden",
    UncompleteMessage: "Opción de Contact Center no habilitada",
    CompleteMessage: "Solicitud. Opción de Contact Center no habilitada",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "existing_voucher",
    CompleteErrorCode: "Solicitud.existing_voucher",
    UncompleteMessage: "Vale existente, no puede continuar.",
    CompleteMessage: "Solicitud. Vale existente, no puede continuar.",
  },
  {
    Servicio: "Solicitud.",
    ErrorCode: "voucher_already_created",
    CompleteErrorCode: "Solicitud.voucher_already_created",
    UncompleteMessage: "Vale ya creado para esta solicitud, verifique.",
    CompleteMessage:
      "Solicitud. Vale ya creado para esta solicitud, verifique.",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "Sucursal.missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "Sucursal. Dato no ingresado",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "is_missing",
    CompleteErrorCode: "Sucursal.is_missing",
    UncompleteMessage: "No se encuentra la sucursal",
    CompleteMessage: "Sucursal. No se encuentra la sucursal",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "State.missing",
    CompleteErrorCode: "Sucursal.State.missing",
    UncompleteMessage: "Falta el estado de la sucursal",
    CompleteMessage: "Sucursal. Falta el estado de la sucursal",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "State.not_found",
    CompleteErrorCode: "Sucursal.State.not_found",
    UncompleteMessage: "No se encuentra el estado de la sucursal",
    CompleteMessage: "Sucursal. No se encuentra el estado de la sucursal",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "Province.missing",
    CompleteErrorCode: "Sucursal.Province.missing",
    UncompleteMessage: "Falta la provincia de la sucursal",
    CompleteMessage: "Sucursal. Falta la provincia de la sucursal",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "Province.not_found",
    CompleteErrorCode: "Sucursal.Province.not_found",
    UncompleteMessage: "No se encuentra la provincia de la sucursal",
    CompleteMessage: "Sucursal. No se encuentra la provincia de la sucursal",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "State.province_not_related",
    CompleteErrorCode: "Sucursal.State.province_not_related",
    UncompleteMessage: "Estado y provincia no relacionados",
    CompleteMessage: "Sucursal. Estado y provincia no relacionados",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "missing_name",
    CompleteErrorCode: "Sucursal.missing_name",
    UncompleteMessage: "Nombre no ingresado.",
    CompleteMessage: "Sucursal. Nombre no ingresado.",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "Sucursal.missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Sucursal. Identificador no recibido.",
  },
  {
    Servicio: "Sucursal.",
    ErrorCode: "not_found",
    CompleteErrorCode: "Sucursal.not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Sucursal. Dato no encontrado. ",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "conexion_error",
    CompleteErrorCode: "Fintech00Service.conexion_error",
    UncompleteMessage: "Servicio Fintech. Error de Conexión.",
    CompleteMessage: "Servicio Fintech. Error de Conexión.",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "no_return_data_error",
    CompleteErrorCode: "Fintech00Service.no_return_data_error",
    UncompleteMessage: "Servicio Fintech. No hay datos de retorno.",
    CompleteMessage: "Servicio Fintech. No hay datos de retorno.",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "json_parsing_error",
    CompleteErrorCode: "Fintech00Service.json_parsing_error",
    UncompleteMessage: "Servicio Fintech. Error al interpretar retorno.",
    CompleteMessage: "Servicio Fintech. Error al interpretar retorno.",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "conexion_exception",
    CompleteErrorCode: "Fintech00Service.conexion_exception",
    UncompleteMessage: "Servicio Fintech. Excepción de Conexión.",
    CompleteMessage: "Servicio Fintech. Excepción de Conexión.",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "authentication_error",
    CompleteErrorCode: "Fintech00Service.authentication_error",
    UncompleteMessage: "Servicio Fintech. Error de Autenticación.",
    CompleteMessage: "Servicio Fintech. Error de Autenticación.",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "internal_error",
    CompleteErrorCode: "Fintech00Service.internal_error",
    UncompleteMessage: "Servicio Fintech. Error interno.",
    CompleteMessage: "Servicio Fintech. Error interno.",
  },
  {
    Servicio: "Fintech00Service.",
    ErrorCode: "no_engine_data",
    CompleteErrorCode: "Fintech00Service.no_engine_data",
    UncompleteMessage:
      "Servicio Fintech. Sin datos de motores de validación. Verificar Licencias.",
    CompleteMessage:
      "Servicio Fintech. Sin datos de motores de validación. Verificar Licencias.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "conexion_error",
    CompleteErrorCode: "ServiciosProSbnService.conexion_error",
    UncompleteMessage: "Servicio PS. Error de Conexión.",
    CompleteMessage: "Servicio PS. Error de Conexión.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "mobile_error",
    CompleteErrorCode: "ServiciosProSbnService.mobile_error",
    UncompleteMessage: "Servicio PS. Error de Celular.",
    CompleteMessage: "Servicio PS. Error de Celular.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "sms_service_error",
    CompleteErrorCode: "ServiciosProSbnService.sms_service_error",
    UncompleteMessage: "Servicio PS. Error de código de activación.",
    CompleteMessage: "Servicio PS. Error de código de activación.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_sms_id",
    CompleteErrorCode: "ServiciosProSbnService.missing_sms_id",
    UncompleteMessage: "Servicio PS. Código de activación no generado.",
    CompleteMessage: "Servicio PS. Código de activación no generado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_email_id",
    CompleteErrorCode: "ServiciosProSbnService.missing_email_id",
    UncompleteMessage:
      "Servicio PS. Código de activación para email no generado.",
    CompleteMessage:
      "Servicio PS. Código de activación para email no generado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_sms_code",
    CompleteErrorCode: "ServiciosProSbnService.missing_sms_code",
    UncompleteMessage: "Servicio PS. Código código de activación no ingresado.",
    CompleteMessage: "Servicio PS. Código código de activación no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "wrong_sms_code",
    CompleteErrorCode: "ServiciosProSbnService.wrong_sms_code",
    UncompleteMessage: "Servicio PS. Código de activación incorrecto.",
    CompleteMessage: "Servicio PS.  Código de activación incorrecto.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_document_file_1",
    CompleteErrorCode: "ServiciosProSbnService.missing_document_file_1",
    UncompleteMessage: "Servicio PS. Frontal no ingresado.",
    CompleteMessage: "Servicio PS. Frontal documento no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_name_file_1",
    CompleteErrorCode: "ServiciosProSbnService.missing_name_file_1",
    UncompleteMessage: "Servicio PS. Nombre de Frontal no ingresado.",
    CompleteMessage: "Servicio PS. Nombre de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_data_file_1",
    CompleteErrorCode: "ServiciosProSbnService.missing_data_file_1",
    UncompleteMessage: "Servicio PS. Datos de Frontal no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_b64_file_1",
    CompleteErrorCode: "ServiciosProSbnService.missing_b64_file_1",
    UncompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_scr_file_1",
    CompleteErrorCode: "ServiciosProSbnService.missing_scr_file_1",
    UncompleteMessage: "Servicio PS. Link de Frontal no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_document_file_2",
    CompleteErrorCode: "ServiciosProSbnService.missing_document_file_2",
    UncompleteMessage: "Servicio PS. Reverso no ingresado.",
    CompleteMessage: "Servicio PS. Reverso no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_name_file_2",
    CompleteErrorCode: "ServiciosProSbnService.missing_name_file_2",
    UncompleteMessage: "Servicio PS. Nombre de Reverso no ingresado.",
    CompleteMessage: "Servicio PS. Nombre de Reverso no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_data_file_2",
    CompleteErrorCode: "ServiciosProSbnService.missing_data_file_2",
    UncompleteMessage: "Servicio PS. Datos de Reverso no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_b64_file_2",
    CompleteErrorCode: "ServiciosProSbnService.missing_b64_file_2",
    UncompleteMessage: "Servicio PS. Base64 de Reverso no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_scr_file_2",
    CompleteErrorCode: "ServiciosProSbnService.missing_scr_file_2",
    UncompleteMessage: "Servicio PS. Link de Reverso no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Frontal no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_pictures",
    CompleteErrorCode: "ServiciosProSbnService.missing_pictures",
    UncompleteMessage: "Servicio PS. Fotos no ingresadas.",
    CompleteMessage: "Servicio PS. Fotos no ingresadas.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_picture_in_list",
    CompleteErrorCode: "ServiciosProSbnService.missing_picture_in_list",
    UncompleteMessage: "Servicio PS. Foto no ingresada.",
    CompleteMessage: "Servicio PS. Foto no ingresada.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_name_pic",
    CompleteErrorCode: "ServiciosProSbnService.missing_name_pic",
    UncompleteMessage: "Servicio PS. Nombre de Foto no ingresado.",
    CompleteMessage: "Servicio PS. Nombre de Foto no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_b64_pic",
    CompleteErrorCode: "ServiciosProSbnService.missing_b64_pic",
    UncompleteMessage: "Servicio PS. Base64 de Foto no ingresado.",
    CompleteMessage: "Servicio PS. Base64 de Foto no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "face_recognition_error",
    CompleteErrorCode: "ServiciosProSbnService.face_recognition_error",
    UncompleteMessage: "Servicio PS. Error de Reconocimiento Facial.",
    CompleteMessage: "Servicio PS. Error de Reconocimiento Facial.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "environment_validation_error",
    CompleteErrorCode: "ServiciosProSbnService.environment_validation_error",
    UncompleteMessage:
      "Servicio PS. Reconocimiento Facial. Mínimo no alcanzado en validación inicial.",
    CompleteMessage:
      "Servicio PS. Reconocimiento Facial. Mínimo no alcanzado en validación inicial.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "score_validation_error",
    CompleteErrorCode: "ServiciosProSbnService.score_validation_error",
    UncompleteMessage:
      "Servicio PS. Reconocimiento Facial. Mínimo no alcanzado en validación global.",
    CompleteMessage:
      "Servicio PS. Reconocimiento Facial. Mínimo no alcanzado en validación global.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "email_service_error",
    CompleteErrorCode: "ServiciosProSbnService.email_service_error",
    UncompleteMessage: "Servicio PS. Error de Email.",
    CompleteMessage: "Servicio PS. Error de Email.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "missing_email_code",
    CompleteErrorCode: "ServiciosProSbnService.missing_email_code",
    UncompleteMessage: "Servicio PS. Código eMail no ingresado.",
    CompleteMessage: "Servicio PS. Código eMail no ingresado.",
  },
  {
    Servicio: "ServiciosProSbnService.",
    ErrorCode: "wrong_sms_code",
    CompleteErrorCode: "ServiciosProSbnService.wrong_sms_code",
    UncompleteMessage: "Servicio PS. Código eMail incorrecto.",
    CompleteMessage: "Servicio PS. Código eMail incorrecto.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "conexion_error",
    CompleteErrorCode: "ProcesadoraService.conexion_error",
    UncompleteMessage: "Procesadora. Error de Conexión.",
    CompleteMessage: "Procesadora. Error de Conexión.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "voucher_generation_error",
    CompleteErrorCode: "ProcesadoraService.voucher_generation_error",
    UncompleteMessage: "Procesadora. Error en la Generación de Vale.",
    CompleteMessage: "Procesadora. Error en la Generación de Vale.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "no_return_data_error",
    CompleteErrorCode: "ProcesadoraService.no_return_data_error",
    UncompleteMessage: "Procesadora. No hay datos de retorno.",
    CompleteMessage: "Procesadora. No hay datos de retorno.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "json_parsing_error",
    CompleteErrorCode: "ProcesadoraService.json_parsing_error",
    UncompleteMessage: "Procesadora. Error al interpretar retorno.",
    CompleteMessage: "Procesadora. Error al interpretar retorno.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "conexion_exception",
    CompleteErrorCode: "ProcesadoraService.conexion_exception",
    UncompleteMessage: "Procesadora. Excepción de Conexión.",
    CompleteMessage: "Procesadora. Excepción de Conexión.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "authentication_error",
    CompleteErrorCode: "ProcesadoraService.authentication_error",
    UncompleteMessage: "Procesadora. Error de Autenticación.",
    CompleteMessage: "Procesadora. Error de Autenticación.",
  },
  {
    Servicio: "ProcesadoraService.",
    ErrorCode: "tyc_getting_error",
    CompleteErrorCode: "ProcesadoraService.tyc_getting_error",
    UncompleteMessage:
      "Procesadora. Error al generar documento de Términos y Condiciones.",
    CompleteMessage:
      "Procesadora. Error al generar documento de Términos y Condiciones.",
  },
  {
    Servicio: "Servicios.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "missing_data",
    UncompleteMessage: "Dato no ingresado",
    CompleteMessage: "Servicios. Dato no ingresado",
  },
  {
    Servicio: "Servicios.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Servicios. Identificador no recibido.",
  },
  {
    Servicio: "Servicios.",
    ErrorCode: "dont_send_id",
    CompleteErrorCode: "dont_send_id",
    UncompleteMessage: "No envie identificador.",
    CompleteMessage: "Servicios. No envie identificador.",
  },
  {
    Servicio: "Servicios.",
    ErrorCode: "not_found",
    CompleteErrorCode: "not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Servicios. Dato no encontrado. ",
  },
  {
    Servicio: "Servicios.",
    ErrorCode: "engine_exception",
    CompleteErrorCode: "engine_exception",
    UncompleteMessage: "Error de conexión con proveedores externos.",
    CompleteMessage: "Servicios. Error de conexión con proveedores externos.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Canal.not_coded",
    CompleteErrorCode: "Canal.not_coded",
    UncompleteMessage: "Canal no encontrado. ",
    CompleteMessage: "Canal no encontrado. ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Step.not_coded",
    CompleteErrorCode: "Step.not_coded",
    UncompleteMessage: "Step no encontrado.",
    CompleteMessage: "Step no encontrado.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Country.missing_iso",
    CompleteErrorCode: "Country.missing_iso",
    UncompleteMessage: "Codificación ISO de País no recibida.",
    CompleteMessage: "Codificación ISO de País no recibida.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Country.not_found",
    CompleteErrorCode: "Country.not_found",
    UncompleteMessage: "Dato no encontrado para País.",
    CompleteMessage: "Dato no encontrado para País.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Document.missing_document_type",
    CompleteErrorCode: "Document.missing_document_type",
    UncompleteMessage: "Tipo de Documento no encontrado.",
    CompleteMessage: "Tipo de Documento no encontrado.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Document.document_type_not_found",
    CompleteErrorCode: "Document.document_type_not_found",
    UncompleteMessage: "Dato no encontrado para vospo de Documento.",
    CompleteMessage: "Dato no encontrado para vospo de Documento.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Document.missing",
    CompleteErrorCode: "Document.missing",
    UncompleteMessage: "Documento no recibido. ",
    CompleteMessage: "Documento no recibido. ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Document.wrong_number",
    CompleteErrorCode: "Document.wrong_number",
    UncompleteMessage: "Número de Documento no válido.",
    CompleteMessage: "Número de Documento no válido.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Document.cannot_change",
    CompleteErrorCode: "Document.cannot_change",
    UncompleteMessage: "No puede cambiar el Documento de Identidad.",
    CompleteMessage: "No puede cambiar el Documento de Identidad.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Mobile.missing",
    CompleteErrorCode: "Mobile.missing",
    UncompleteMessage: "Celular no ingresado.",
    CompleteMessage: "Celular no ingresado.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Person.mail_used_other_user",
    CompleteErrorCode: "Person.mail_used_other_user",
    UncompleteMessage: "Mail ya en uso.",
    CompleteMessage: "Mail ya en uso.",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Organization.missing",
    CompleteErrorCode: "Organization.missing",
    UncompleteMessage: "Organización. Dato no ingresado",
    CompleteMessage: "Organización. Dato no ingresado",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Organization.no_matching",
    CompleteErrorCode: "Organization.no_matching",
    UncompleteMessage:
      "Organización. No corresponde con Organización autorizada. ",
    CompleteMessage:
      "Organización. No corresponde con Organización autorizada. ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Organization.no_matching",
    CompleteErrorCode: "Organization.no_matching",
    UncompleteMessage: "Organización. Dato no encontrado. ",
    CompleteMessage: "Organización. Dato no encontrado. ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "TYC.missing_data",
    CompleteErrorCode: "TYC.missing_data",
    UncompleteMessage: "Falta información de terminos y condiciones ",
    CompleteMessage: "Core.Falta información de terminos y condiciones ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "TYC.cannot_continue",
    CompleteErrorCode: "TYC.cannot_continue",
    UncompleteMessage: "Terminos y condiciones no permite continuar ",
    CompleteMessage: "Core.Terminos y condiciones no permite continuar ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Validation.mobile_not_validated",
    CompleteErrorCode: "Validation.mobile_not_validated",
    UncompleteMessage: "Telefono no validado ",
    CompleteMessage: "Core.Telefono no validado ",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Validation.email_not_validated",
    CompleteErrorCode: "Validation.email_not_validated",
    UncompleteMessage: "Email no validado",
    CompleteMessage: "Core.Email no validado",
  },
  {
    Servicio: "Core.",
    ErrorCode: "Validation.face_recog_not_validated",
    CompleteErrorCode: "Validation.face_recog_not_validated",
    UncompleteMessage: "Reconocimiento facial no validado",
    CompleteMessage: "Core.Reconocimiento facial no validado",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "missing_data",
    CompleteErrorCode: "missing_data",
    UncompleteMessage: "Dato no ingresado (Situación inicial)",
    CompleteMessage: "Dato no ingresado (Situación inicial)",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "missing_id",
    CompleteErrorCode: "missing_id",
    UncompleteMessage: "Identificador no recibido.",
    CompleteMessage: "Identificador no recibido.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "invalid_id",
    CompleteErrorCode: "invalid_id",
    UncompleteMessage: "Identificador no válido.",
    CompleteMessage: "Identificador no válido.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "not_found",
    CompleteErrorCode: "not_found",
    UncompleteMessage: "Dato no encontrado. ",
    CompleteMessage: "Dato no encontrado. ",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "missing_since_date",
    CompleteErrorCode: "missing_since_date",
    UncompleteMessage: "Fecha Desde no ingresada. ",
    CompleteMessage: "Fecha Desde no ingresada. ",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "missing_due_date",
    CompleteErrorCode: "missing_due_date",
    UncompleteMessage: "Dato no ingresado (Fecha hasta)",
    CompleteMessage: "Dato no ingresado (Fecha hasta)",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "invalid_due_date",
    CompleteErrorCode: "invalid_due_date",
    UncompleteMessage: "Fecha de Expiración no válida.",
    CompleteMessage: "Fecha de Expiración no válida.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "invalid_date_range",
    CompleteErrorCode: "invalid_date_range",
    UncompleteMessage: "Rango de Fechas no válido.",
    CompleteMessage: "Rango de Fechas no válido.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "invalid_amount",
    CompleteErrorCode: "invalid_amount",
    UncompleteMessage: "Monto no válido.",
    CompleteMessage: "Monto no válido.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "no_previous_debt",
    CompleteErrorCode: "no_previous_debt",
    UncompleteMessage: "No hay deuda previa.",
    CompleteMessage: "No hay deuda previa.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "amount_greater_than_debt",
    CompleteErrorCode: "amount_greater_than_debt",
    UncompleteMessage: "Monto no puede ser mayor a la deuda registrada.",
    CompleteMessage: "Monto no puede ser mayor a la deuda registrada.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "Receipt.missing_data",
    CompleteErrorCode: "Receipt.missing_data",
    UncompleteMessage: "Dato no ingresado (Comprobante)",
    CompleteMessage: "Dato no ingresado (Comprobante)",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "Receipt.already_used",
    CompleteErrorCode: "Receipt.already_used",
    UncompleteMessage: "Comprobante ya ingresado previamente.",
    CompleteMessage: "Comprobante ya ingresado previamente.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "cannot_upd_or_delete",
    CompleteErrorCode: "cannot_upd_or_delete",
    UncompleteMessage: "Registro activo, no puede ser modificado o borrado.",
    CompleteMessage: "Registro activo, no puede ser modificado o borrado.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "update_error",
    CompleteErrorCode: "update_error",
    UncompleteMessage: "Error al actualizar.",
    CompleteMessage: "Error al actualizar.",
  },
  {
    Servicio: "SituacionInicial.",
    ErrorCode: "no_previous_debt",
    CompleteErrorCode: '"SituacionInicial.no_previous_debt"',
    UncompleteMessage: "Sin deuda previa.",
    CompleteMessage: "Sin deuda previa.",
  },
  {
    Servicio: "TopesTasa.",
    ErrorCode: "topes_tasa_error",
    CompleteErrorCode: "TopesTasa.no_current_data",
    UncompleteMessage: "Error al buscar tasas.",
    CompleteMessage:
      "No hay tope de tasas cargadas. Contactese con el Administrador del Sistema.",
  },
];

export default errores;
